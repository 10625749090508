import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import source from "../assets/images/about-img.jpeg"
import packageOne from "../assets/images/Package1.png"
import packageTwo from "../assets/images/Package2.png"
import packageThree from "../assets/images/Package3.png"
import portfolioImage from "../assets/images/portfolio-image.jpeg"
import waves from "../assets/images/waves.png"


import Hero from "../components/hero"
import IntakeForm from "../components/intake-form"


import ScrollAnimation from 'react-animate-on-scroll';
import { Parallax } from 'react-scroll-parallax';



class RootIndex extends React.Component {
  constructor() {
    super();
    this.state = { show: false, padding:'100px' };
    this.toggleModal = this.toggleModal.bind(this);
}

toggleModal() {
    this.setState((state) => {
        return {...this.state, show: !this.state.show }
    });
}



  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulBlogPost.edges')
    const [author] = get(this, 'props.data.allContentfulPerson.edges')

    return (
      <Layout location={this.props.location}>
        <Hero toggleModal={this.toggleModal}/>
        <Container>
        <Row id="about-row" className="double-space-bottom">
            <Col md="8">
              <h2>How We Work</h2>
              <p><b><i>nLight </i></b> is run by an energetic, intelligent team of individuals from diverse professional backgrounds ranging from Silicon valley startups to Corporate tech teams to full scale digital agencies. We are understand what works for who, and why.
              <br/><br/><span style={{fontSize:'24px', fontWeight:'700'}}>We really don't like sales calls.</span><br/><br/>
              Don't misunderstand - we're happy to talk anytime that it's helpful, but we'd much rather show you what we can do instead of 
              try to convince you of our abilities with speeches and powerpoints. Our process is centered around our <a onClick={this.toggleModal}>Design Assessment</a>, an AI-powered intake form that allows us to assess your needs 
              and present you with clear visuals to guide how we can move forward together. If you can give us 7 minutes to go through the form, <span style={{fontSize:'20px', fontWeight:'700', fontStyle:'italic'}}>we will be able to present you with several comps, for free, within 48 hours.</span>
              <br/><br/>
              <span style={{fontSize:'20px', fontWeight:'700'}}>You have literally nohing to loose and everything to gain!</span>
              </p>
              <Button size="md" onClick={this.toggleModal}>Get Started</Button>
            </Col>
            <Col className="my-auto" md="4">
              <img src={source}/>
            </Col>
          </Row>
          </Container>
          <Row id="packages-row" className="space slanted">
            <Col>
            <h2 className="white center">Website Options</h2>
            <Container>
            <Row className="space">
            <Col>
            <ScrollAnimation animateIn="slideInUp"> 
              <img src={packageOne}/>
              <h3>Modern Responsive Website</h3>
              <ul class="package-feature-list">
                <li>
                  Crisp design in accordance with your brand's aesthetic.
                </li>
                <li>
                  Perfectly responsive, cross browser and multi device tested.
                </li>
                <li>
                  Best optimization practices for high speed and ideal performance.
                </li>
              </ul>
              <a href="/portfolio"><Button className="package-button">SEE EXAMPLES</Button></a>
              </ScrollAnimation>
            </Col>
            <Col>
            <ScrollAnimation animateIn="slideInUp" delay={400}> 
            <img src={packageTwo}/>
              <h3>Advanced Creative Website</h3>
              <ul class="package-feature-list">
                <li>
                  <b><i>Modern responsive</i></b> plus...
                </li>
                <li>
                  Highest quality, custom tailored imagery
                </li>
                <li>
                  Visually cutting edge with animation, parallax and interactivity.
                </li>
              </ul>
              <a href="/portfolio"><Button className="package-button">SEE EXAMPLES</Button></a>
              </ScrollAnimation>
            </Col>
            <Col>
            <ScrollAnimation animateIn="slideInUp" delay={800}> 
            <img src={packageThree}/>
              <h3>Expert Customized Website</h3>
              <ul class="package-feature-list">
                <li>
                <b><i>Modern responsive</i></b> + <b><i>Advanced Creative</i></b> plus...
                </li>
                <li>
                  Wholly unique design including custom UI components
                </li>
                <li>
                  Advanced functionality i.e. complex e-commerce, API development, etc.
                </li>
              </ul>
              <a href="/portfolio"><Button className="package-button">SEE EXAMPLES</Button></a>
              </ScrollAnimation>
            </Col> 
            </Row>
            </Container>
            <Parallax y={[25, 0]} >
              <img src={waves}/>
          </Parallax>
            </Col>
          </Row>
          <Container>
          <Row className="double-space-top" id="portfolio-row">
                <Col md="4">
                  <img src={portfolioImage}/>
                </Col>
                <Col className="my-auto" md="8">
                  <h2 className="right">Our Work</h2>
                  <p className="right">Whether you're a shop needing e-commerce, a creative showcasing your work, a business generating leads or anything in between, 
                  <b><i> nLight </i></b>delivers state of the art web development that serves your goals at every turn.</p>
                  <Button className="portfolio-button">OUR WORK</Button>
                </Col>
          </Row>
          </Container>
          <IntakeForm toggleModal={this.toggleModal} show={this.state.show}/>
      </Layout>
    )
  }
}

export default RootIndex

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    allContentfulPerson(
      filter: { contentful_id: { eq: "15jwOBqpxqSAOy2eOO4S0m" } }
    ) {
      edges {
        node {
          name
          shortBio {
            shortBio
          }
          title
          heroImage: image {
            fluid(
              maxWidth: 1180
              maxHeight: 480
              resizingBehavior: PAD
              background: "rgb:000000"
            ) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`
