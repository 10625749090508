import React, { useState, useLayoutEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import sourceVideo from "../assets/videos/hero.mp4"
import $ from 'jquery';
import { Parallax } from 'react-scroll-parallax';


const Hero = props => {
  const { toggleModal } = props
  const [padding, setPadding] = useState('100px 0')

  useLayoutEffect(() => {
    const headlineHeight = $('#hero-headline').height();
    const buttonHeight = document.getElementById('hero-button').getBoundingClientRect().height
    const height = headlineHeight + buttonHeight
    console.log(headlineHeight, buttonHeight, height)
    const verticalPadding = ((550 - height) / 2) + 'px'
    //setPadding(`${verticalPadding} 0`)
    setPadding('180px 0')

  }, [])

  return (
    <Row style={{position:'relative'}} className="my-auto" id="hero">
      <Col>
        <div className="video-container">
          <video id="vid" width="100%" height="550"  autoplay="true" muted="muted" loop>
            <source src={sourceVideo} type="video/mp4" />
          </video>
        </div>
        <div style={{ padding: padding }} id="hero-content-container" className="white-overlay my-auto">
          <Container>
          <Parallax y={[-20, 20]} >
            <h1 id="hero-headline">AI Powered Organization meets Human Centered Design</h1>
            <Button id="hero-button" size="lg" onClick={toggleModal}>Start Building</Button>
          </Parallax> 
          </Container>
        </div>
      </Col>
    </Row>

  )

}

export default Hero
