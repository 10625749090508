import React, { useContext } from 'react'
import Modal from 'react-bootstrap/Modal'
import {ReactTypeformEmbed} from 'react-typeform-embed'


const IntakeForm = props => {

  const { show, toggleModal } = props

  return (
    <Modal show={show}>
      <Modal.Header>
        <a id="modal-close-button" href="#" onClick={toggleModal}>X</a>
      </Modal.Header>
      <Modal.Body>
        <ReactTypeformEmbed url="https://dankurfirst.typeform.com/to/GAQbszXC" />
      </Modal.Body>
    </Modal>
  )

}

export default IntakeForm